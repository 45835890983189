import * as React from "react";
import {TextAccent, TextBlock, TitleAccent} from "../styles";
import {styled} from "@mui/system";

const Wrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 25,
  padding: "0 20px",
  margin: "28px auto 0",
  textAlign: "center",
  "@media(max-width: 576px)":{
    gap: 44,
    margin: "86px auto 0",
  },
})

const HeaderBlock = () =>{
  return(
    <Wrap>
      <TitleAccent>
        Team
      </TitleAccent>
      <TextBlock>
        Human capital is our main resource.<br/>
        We call it <TextAccent>Graphene Ventures family</TextAccent>.
      </TextBlock>
    </Wrap>
  )
}

export default HeaderBlock
