import {styled} from "@mui/system";

export const Wrap = styled("div")({
    maxWidth:"1280px",
    margin: "0 auto",
})

export const Label = styled("span")({
    fontFamily: "Heiti TC",
    fontWeight: 400,
})

export const Title = styled(Label)({
    fontSize: "66px",
    lineHeight: "135%",

    "@media(max-width: 1024px)":{
        fontSize: "48px",
        maxWidth: "460px"
    },

    "@media(max-width: 576px)":{
        fontSize: "28px",
    }
})

export const LabelAccent = styled(Label)({
    background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
    backgroundClip: "text",
    textFillColor: "transparent"
})

export const TextBlock = styled("div")({
    fontFamily: 'Merriweather',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "21px",
    lineHeight: "150%",
    paddingTop: "7px",
})

export const TextAccent = styled("span")({
    background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
    backgroundClip: "text",
    textFillColor: "transparent"
})

export const TitleAccent = styled(LabelAccent)({
    fontSize: "66px",
    lineHeight: "135%",

    "@media(max-width: 1024px)":{
        fontSize: "45px",
        maxWidth: "555px"
    },

    "@media(max-width: 576px)":{
        fontSize: "28px",
        maxWidth: "409px"
    }
})

export const OutlinedInputContent = styled("div")({
    borderRadius: 4,
    border: "1px solid #E3E3E3",
    width: "100%",
    padding: "11.7px",

    "&.error":{
        border: "1px solid #D8001B"
    }
})

export const OutlinedInputWrap = styled("div")({
    width:"100%"
})

export const OutlinedTitle = styled("label")({
    color: "#C4C4C4",
    fontFamily: "Merriweather",
    fontWeight: 400,
    fontSize: "0.8rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    margin: "-12px 0 0 10px",
    backgroundColor: "white",
    textTransform: "uppercase",
    display:"inline-block",
    position: "absolute",
    padding: "0 6px 6px 6px",

    "@media(max-width: 576px)":{
        fontSize: "0.8rem",
        margin: "-10px 0 0 8px",
    },

    "&.error":{
        color: "#D8001B"
    }
})

export const ModalWrap = styled("div")({
    width: "100%",
    minHeight: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 5
})

