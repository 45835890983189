import * as React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {styled} from "@mui/system";
import GradientLine from "../../static/svg/blue_grad_rect.svg"
import {TextAccent} from "../styles";
import {Link} from "gatsby";

const Wrap = styled("div")({
  maxWidth: "75vw",
  padding: "0 20px",
  margin: "0 auto",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  rowGap: 120,
  columnGap: 33,
  "@media(max-width: 1336px)":{
    padding: "0 28px",
  },
  "@media(max-width: 1024px)": {
    padding: "0 28px",
    gridTemplateColumns: "1fr 1fr 1fr",
    maxWidth:"100%",
    rowGap: 80,
    columnGap: 25,
  },
  "@media(max-width: 576px)": {
    padding: "0 20px",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 17,
  }
})

const TeamMembers = ({data}) => {
  return (
    <Wrap>
      {data.nodes.map(
        ({name, position, profile_photo, strapi_id, show_bio_page}) =>
          <MemberCard position={position}
                      key={name}
                      strapiId={strapi_id}
                      photo={profile_photo}
                      name={name}
                      show_bio_page={show_bio_page}/>
      )}
    </Wrap>
  )
}

const CardWrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
})

const MemberImage = styled("div")({
  position: "relative",
  background: "#D9D9D9",
  cursor: "pointer",
  // "&:hover div":{
    //   opacity: 0.55,
    //   transition: "opacity 0.25s ease-out"
    // },
    ":hover img":{
      filter: "grayscale(1)",
    // opacity: 0.55
  },

  "@media(max-width: 576px)":{
    height: "61vw",
    width: "100%",
    maxHeight: "initial"
  }
})

const MemberNameWrap = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 10,
  marginTop: 20
})

const Name = styled(TextAccent)({
  fontFamily: "Heiti TC",
  fontSize: "1.7vw",
  letterSpacing: "-0.01em",
  lineHeight: "120%",
  flexShrink: 0,

  "@media(max-width:1024px)": {
    fontSize: "3vw",
  },

  "@media(max-width:576px)": {
    fontSize: "4vw"
  }
})

const Position = styled("div")({
  fontFamily: "Merriweather",
  fontSize: 18,
  color: "#222222",
  lineHeight: "150%",
  marginTop: 7,
  "@media(max-width: 1280px)": {
    fontSize: "1.5vw"
  },
  "@media(max-width:1024px)": {
    fontSize: "2vw",
  },
  "@media(max-width:576px)": {
    fontSize: "3.2vw",
  }
})

const HoverBack = styled("div")({
  position: "absolute",
  zIndex: 10,
  top: 0,
  width: "100%",
  height: "100%",
  background: "#0870E0",
  opacity: 0,
  "&:hover":{
    opacity: 0.22,
    transition: "opacity 0.25s ease-out"
  }
})

const MemberCard = ({name, position, photo, strapiId, show_bio_page}) => {
  const gatsbyImageData = photo.localFile.childImageSharp.gatsbyImageData

  let insideLink = name.toLowerCase()
    .replace('.', '')
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')

  return (
    <Link to={show_bio_page ? `${insideLink}` : null} style={{textDecoration: "none"}}>
      <CardWrap>
        <MemberImage>
          <HoverBack />
          <GatsbyImage style={{height: "100%", width: "100%", aspectRatio: "7/10",}} alt={name} image={gatsbyImageData}/>
        </MemberImage>
        <MemberNameWrap>
          <Name>
            {name}
          </Name>
          <GradientLine/>
        </MemberNameWrap>
        <Position>
          {position}
        </Position>
      </CardWrap>
    </Link>
  )
}

export default TeamMembers