import * as React from "react";
import Layout from "../components/layout";
import HeaderBlock from "../components/team/headerBlock";
import GridLines from "../components/gridLines";
import TeamMembers from "../components/team/teamMemberCard";
import {graphql} from "gatsby";
import Seo from "../components/seo"


const Team = ({data})=>{
  return(
    <Layout headerDark={false}>
      <Seo title="Team" description={`We rely on people as our main resource and we call our team the Graphene Ventures family.`}/>
      <HeaderBlock/>
      <GridLines
        styles={{
          height: 80,
          heightMob: 60,
          marginTop: 28,
          marginTopMob: 28,
          marginBottom: 28,
          marginBottomMob:28,
        }}
      />
      <TeamMembers data={data.allStrapiOurTeamMember}/>
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 118,
          marginTopMob: 80
        }}
      />
    </Layout>
  )
}

export const query = graphql`
query TeamPageQuery {
  allStrapiOurTeamMember(sort: { fields: rank, order: ASC }) {
    nodes {
      rank
      position
      name
      strapi_id
      show_bio_page
      profile_photo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  }
}

`

export default Team